/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.1.0/lazysizes.min.js
 * - /gh/highlightjs/cdn-release@9.15.8/build/highlight.min.js
 * - /npm/mdui@0.4.3/dist/js/mdui.min.js
 * - /gh/nexmoe/nexmoe.github.io@master/js/app.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
